<template>
  <div class="totalBg">
    <div class="questionTitle">
      <div class="QuestionHeader-content">
        <div class="QuestionHeader-main">
          <div class="quesiontTips">
            <!-- <div v-for="i in getTagName(detailData.postTagsId)" :key="i.F_Id" class="tag">{{ i.Tag_name }}</div> -->
            <div class="tag">{{ detailData.postSmboidtitle }}</div>
          </div>
          <div class="question_title">{{ detailData.postTitle }}</div>
          <div v-if="detailData.postContent.length < 10">
            <div v-html="detailData.postContent"></div>
          </div>
          <div v-else style="font-size: 15px;">
            <div v-html="showFoldBtn ? sliceStr : detailData.postContent"></div>
            <span class="foldBtn" @click="showFoldBtn = !showFoldBtn">{{ showFoldBtn ? '显示全部' : '收起' }}
            </span>
          </div>
          <div class="questionOp">
            <el-button icon="el-icon-edit" style="margin-right: 20px;color: #056de8;border-color: #056de8;" size="medium"
              @click="showEditRichText = true">写回答</el-button>
            <div class="opBtn" @click="clickToComment">
              <span class="iconfont icon-comment_2_line opIcon"></span>
              <span class="opText">{{ replayTotalCount }}条回复</span>
            </div>
            <!-- <div class="opBtn" @click="clickPostCollect" :style="isCollected ? 'color: #1e80ff' : ''">
              <span class="iconfont icon-star_line opIcon"></span>
              <span class="opText">收藏</span>
            </div>
            <div class="opBtn" @click="clickPostLike" :style="isClickLike ? 'color: #1e80ff' : ''">
              <span class="iconfont icon-dianzan_kuai opIcon"></span>
              <span class="opText">好问题</span>
            </div> -->
            <div class="opBtn" v-if="isLogin && detailData.postAdminid == userInfo.id" @click="delPost()">
              <span class="opText">删除问题</span>
            </div>
            <!-- <div style="line-height: 37px;" @click="addCollect"><span class="iconfont icon-star_line"
                  style="margin-right: 8px;font-size: 25px;"></span>
                <span style="font-size: 15px;vertical-align: super;">收藏</span>
              </div> -->
          </div>
        </div>
        <div class="QuestionHeader-side">
          <div class="QuestionHeader-follow-status">
            <div class="QuestionFollowStatus">
              <div class="NumberBoard QuestionFollowStatus-counts NumberBoard--divider">
                <!-- <div class="NumberBoard-item">
                  <div class="NumberBoard-itemInner collectNum">
                    <div class="NumberBoard-itemName">收藏</div><strong class="NumberBoard-itemValue">{{
                      detailData.postCollectNum }}</strong>
                  </div>
                </div> -->
                <div class="NumberBoard-item">
                  <div class="NumberBoard-itemInner">
                    <div class="NumberBoard-itemName">被浏览</div><strong class="NumberBoard-itemValue">{{
                      detailData.postWatch }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: rgba(240, 245, 255, 0.95);">
      <div style="height:10px"></div>
      <div class="container" v-show="showEditRichText">
        <Wangeditor class="editorContent" ref="richText"></Wangeditor>
        <el-button type="primary" style="display: block;margin: 10px 10px 10px auto;"
          @click="sendComment">发布回答</el-button>
      </div>
      <div class="containerComment" id="commentListTop">
        <el-row>
          <el-col :span="18">
            <div class="commentList">
              <div class="commentCount">
                {{ detailData.postCommentcount }} 个回答
              </div>
              <div class="commmentContent">
                <div style="padding: 10px 0;border-top: 1px solid #f6f6f6;" v-for="item in commentList" :key="item.F_Id">
                  <div class="commentItem">
                    <div class="commentTitle">
                      <div style="display: flex;align-items: center;">
                        <!-- <img :src="define.APIURl + item.adminMes.headIcon" alt=""
                          @error="e => e.target.src = 'https://img.js.design/assets/smartFill/img326164da748e08.jpeg'"> -->
                        <div class="block"><el-avatar shape="square" size="medium" :src="squareUrl"></el-avatar></div>
                        <span style="vertical-align: inherit;margin-left: 10px;font-size: 15px;font-weight: 700;">{{
                          item.commentAdminname }}</span>
                      </div>
                      <!-- <div>
                        <el-button @click="clickFocus(item)" size="small" type="primary"
                          v-if="item.isFocus && item.isFocus.length > 0">已关注</el-button>
                        <el-button @click="clickFocus(item)" size="small" type="primary" icon="el-icon-plus"
                          v-else>关注</el-button>
                      </div> -->
                    </div>
                    <div class="commentContext" v-html="item.commentContent">
                    </div>
                    <div class="commentTime">
                      发布于 {{ item.commentCreatetime }}
                    </div>
                    <div class="questionOp">
                      <!-- <div class="opBtn" @click="clickPostCollect" :style="isCollected ? 'color: #1e80ff' : ''">
                        <span class="iconfont icon-star_line opIcon"></span>
                        <span class="opText">收藏</span>
                      </div> -->
                      <div class="opBtn" @click="showModule(item)">
                        <span class="iconfont icon-comment_2_line opIcon"></span>
                        <span class="opText">{{ item.isShowReplyModule ? '取消回复' : '添加回复' }}</span>
                      </div>
                      <!-- <div class="opBtn" @click="clickCommentLike(item)"
                        :style="item.is_digg != null && item.is_digg.length != 0 ? 'color: #1e80ff' : ''">
                        <span class="iconfont icon-dianzan_kuai opIcon"></span>
                        <span class="opText">好回答</span>
                      </div> -->
                      <!-- <div class="opBtn">
                        <span class="opText">举报</span>
                      </div> -->
                      <div class="opBtn delButton" v-if="isLogin && item.commentAdmin == userInfo.id"
                        @click="delComment(item.id)" style="color:red">
                        <span class="opText">删除</span>
                      </div>
                    </div>
                  </div>
                  <!-- 回复框 -->
                  <div v-if="item.isShowReplyModule">
                    <div class="commentContent">
                      <el-input v-model="replyText" type="textarea" :rows="2"
                        :placeholder="'回复: ' + item.commentAdminname" ref="inputModule">
                      </el-input>
                      <div style="display: flex;justify-content: flex-end;padding: 10px 0;">
                        <el-button type="primary" size="mini" @click="sendReply(item)">发布</el-button>
                      </div>
                    </div>
                  </div>
                  <!-- 回复列表 -->
                  <div class="reply" v-for="(replyItem) in item.replyChildren" :key="replyItem.Id">
                    <div class="commentLogo">
                      <!-- <el-avatar :src="replyItem.adminMes.headIcon"></el-avatar> -->
                      <div class="block"><el-avatar shape="square" size="medium" :src="squareUrl"></el-avatar></div>
                    </div>
                    <div class="commentContent">
                      <div class="commentContentSelf">
                        <div style="margin: 10px 0">
                          <span class="name">{{ replyItem.replyAdminname }}</span>
                          <span style="color: #6a7780;margin-right: 10px;" v-if="replyItem.replyPostadminid">回复</span>
                          <span class="name" v-if="replyItem.replyPostadminid">{{ replyItem.replyPostadminname
                          }}</span>
                          <span class="time">{{
                            replyItem.replyCreatetime
                          }}</span>
                          <!-- {{ i + 1 }}楼 -->
                          <!-- <span class="time" style="float: right">
                            <div class="click_hover"
                              style="width: 13px;height: 13px;line-height: 1;vertical-align: text-top;display: inline-block;margin:0 5px;"
                              @click="clickReplyLike(replyItem)">
                              <span class="logo icon iconfont"
                                :style="replyItem.is_digg != null && replyItem.is_digg.length != 0 ? 'color: #1e80ff' : ''">{{
                                  replyItem.is_digg != null && replyItem.is_digg.length != 0 ?
                                  "&#xe869;" : "&#xec7f;" }}</span>
                            </div>
                            {{ replyItem.ReplyGoodcount }}
                          </span> -->
                        </div>
                        <div style="font-size: 16px">{{ replyItem.replyContent }}</div>
                        <div class="replayButtonSingle click_hover"
                          :style="replyItem.isShowReplyModule ? 'color: #1e80ff' : ''">
                          <span v-show="isLogin && replyItem.replyAdmin == userInfo.id" class="delButton"
                            style="color:red" @click="delReply(replyItem.id)">删除</span>
                          <span @click="showModule(replyItem)">
                            <span class="logo iconfont icon-xiaoxi1"></span>{{
                              replyItem.isShowReplyModule ? '取消回复' :
                              '回复' }}
                          </span>
                        </div>
                      </div>

                      <!-- 回复回复框 -->
                      <div v-if="replyItem.isShowReplyModule">
                        <div class="commentContent">
                          <el-input v-model="replyText" type="textarea" :rows="2"
                            :placeholder="'回复: ' + replyItem.replyAdminname" ref="inputModule">
                          </el-input>
                          <div style="display: flex;justify-content: flex-end;padding: 10px 0;">
                            <el-button type="primary" size="mini" @click="sendReplyReply(replyItem)">发布</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="rightPart">
              <div class="card_block rightCard">
                <div class="hotpot-title">相关文章</div>
                <div class="hotpot-content">
                  <ul>
                    <li @click="toDetail(i.id)" v-for="i in tagPostList" :key="i.F_Id">
                      {{ i.postTitle }}<i class="el-icon-view">{{ i.postWatch }}</i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>


  </div>
</template>

<script>
import {
  GetForumPostsDetail,
  GetForumCommentList,
  AddForumComment,
  AddForumReply,
  GetForumModuleTagList,
  GetCommentList,
  GetForumPostsList,
  EditForumPost,
  GetForumCommentListWithUser,
  DelForumPost,
  DeleteFocus,
  AddFocus,
  AddLike, DeleteLike, GetCollectStatus, DeleteCollect, AddCollect, EditComment, EditReply, DelComment, DelReply, GetFilterTagList
} from "@/assets/API/forum.js";
import { mapState, mapMutations, mapActions } from "vuex";
import Wangeditor from '@/components/wang/wangeditorQandA';

export default {
  name: "QuestionDetail", // todo name命名是否有规范
  props: {},
  components: { Wangeditor },
  data() {
    return {
      detailData: {
        postTagsId: '[]',
        postContent: ''
      },
      commentList: [],
      replayTotalCount: 0,
      replyList: [],
      tagList: [],
      dialogVisible: false,
      currentComment: {
        replyChildren: [],
      },
      isCatalog: false,
      commentText: "",
      replyText: "",
      isClickLike: false,
      isFocused: false,
      focusedId: "",
      adminQcount: 0,
      adminAcount: 0,
      showFoldBtn: true,
      showEditRichText: false,
      isCollected: false,
      tagPostList: [],
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
    };
  },
  created() {

  },
  computed: {
    ...mapState("user", ["isLogin", "userInfo"]),
    showLoginPane: {
      set(val) {
        this.SET_LOGINVISBILIT(val);
      },
      get() {
        return this.$store.state.user.showLoginPane;
      },
    },
    sliceStr() {
      if (this.detailData.postContent != null) {
        return this.detailData.postContent.substring(0, 45) + "...";
      }
      return '';
    }
  },
  watch: {
    isLogin: {
      handler(val) {
        if (val) {
          // this.getAdminData();
          this.getAllData();
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      let id = this.$route.query.id;
      this.showEditRichText = this.$route.query.showRichText == 1 ? true : false;
      GetForumPostsDetail(id).then((res) => {
        this.detailData = { ...res.data };
        if (this.detailData.is_digg != null && this.detailData.is_digg.length != 0) {
          this.isClickLike = true;
        }
      }).then((res) => {
        this.getAllData();
      });
    });
  },
  methods: {
    ...mapMutations("user", ["SET_LOGINVISBILIT"]),
    getReplayTotalCount() {
      console.log(this.commentList, 'iii');
      let count = 0;
      for (var i in this.commentList) {
        count = count + this.commentList[i].replyChildren.length;
      }
      count = count + this.commentList.length;
      console.log(count, 'uuuu');
      this.replayTotalCount = count;
    },
    // getAdminData() {
    //   // self send question
    //   let queryData = {
    //     Post_adminId: this.userInfo.userId,
    //     Post_SmBoid: this.detailData.postSmBoid,
    //   };
    //   GetForumPostsList(queryData).then((res) => {
    //     this.adminQcount = res.data.pagination.total;
    //   });

    //   let queryAdminReply = {
    //     Comment_admin: this.userInfo.userId,
    //     Comment_smboid: this.detailData.postSmBoid,
    //   };
    //   GetCommentList(queryAdminReply).then((res) => {
    //     this.adminAcount = res.data.pagination.total;
    //   });
    // },
    getReply(i) {
      this.currentComment = i;
      this.dialogVisible = true;
      this.replyList = i.replyChildren;
    },
    handleClose(done) {
      done();
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
    },
    sendComment() {
      if (this.isLogin == false) {
        this.$router.replace({
          name: "Login",
          path: "/login",
        });
      } else {
        let commentData = {
          commentPostid: this.detailData.id,
          commentContent: this.$refs.richText.getEditorText(),
          commentGoodcount: 0,
          commentBadcount: 0,
          commentScore: 0,
          commentAdmin: this.userInfo.id,
          commentAdminname: this.userInfo.username,
          commentPosttitle: this.detailData.postTitle,
        };
        commentData.commentCreatetime = new Date().getTime();
        AddForumComment(commentData).then((res) => {
          this.$message("发表成功！");
          this.showEditRichText = false;
          this.getAllData();
        });
      }
    },
    sendReply(item) {
      if (this.isLogin == false) {
        this.$router.replace({
          name: "Login",
          path: "/login",
        });
      } else {
        let replyData = {
          replyPostid: item.id,
          replyContent: this.replyText,
          replyGoodcount: 0,
          replyBadcount: 0,
          replyScore: 0,
          replyAdmin: this.userInfo.id,
          replyAdminname: this.userInfo.username,
          replyPostcontent: item.commentContent,
          replyPostadminid: item.commentAdmin,
          replyPostadminname: item.commentAdminname
        };
        replyData.replyCreatetime = new Date().getTime();
        AddForumReply(replyData).then((res) => {
          this.$message("发表成功！");
          // this.getReply(res.data[0]);
          this.replyText = "";
          item.isShowReplyModule = false;
          this.getAllData();
        });
      }
    },
    sendReplyReply(item) {
      if (this.isLogin == false) {
        this.$router.replace({
          name: "Login",
          path: "/login",
        });
      } else {
        let replyData = {
          replyPostid: item.ReplyPostid,
          replyContent: this.replyText,
          replyGoodcount: 0,
          replyBadcount: 0,
          replyScore: 0,
          replyAdmin: this.userInfo.id,
          replyAdminName: this.userInfo.username,
          replyPostContent: item.ReplyContent,
          replyPostAdminId: item.ReplyAdmin,
          replyPostAdminName: item.ReplyAdminName
        };
        replyData.replyCreatetime = new Date().getTime();
        AddForumReply(replyData).then((res) => {
          this.$message("发表成功！");
          // this.getReply(res.data[0]);
          this.replyText = "";
          item.isShowReplyModule = false;
          this.getAllData();
        });
      }
    },
    showModule(i) {
      this.replyText = "";
      let result = !i.isShowReplyModule;
      this.commentList.forEach(e => {
        e.isShowReplyModule = false;
        e.replyChildren.forEach(j => {
          j.isShowReplyModule = false;
        });
      });
      i.isShowReplyModule = result;
      if (result == true) {
        this.$nextTick(() => {
          this.$refs.inputModule[0].focus();
        });
      }
    },
    getCommentList() {
      let id = this.detailData.id;
      let userId = this.userInfo ? this.userInfo.id : null;
      // 获取评论列表
      GetForumCommentListWithUser(id, userId)
        .then((res) => {
          this.commentList = [];
          res.data.list.forEach((i) => {
            i.isShowReplyModule = false;
            this.commentList.unshift(i);
          });
        })
        .then((res) => {
          this.getReplayTotalCount();
        });
    },
    clickPostLike() {
      if (this.isLogin == false) {
        this.showLoginPane = true;
      } else {
        let id = this.$route.query.id;
        if (this.isClickLike) {
          this.detailData.postLikeNum = this.detailData.postLikeNum - 1;
          DeleteLike(this.detailData.is_digg[0].Id).then(res => {
            this.detailData.is_digg = [];
          });
          EditForumPost(id, this.detailData).then((res) => {
            this.isClickLike = false;
          });
        } else {
          this.detailData.postLikeNum = this.detailData.postLikeNum + 1;
          let query = {
            likeAdmin: this.userInfo.username,
            likeAdminId: this.userInfo.id,
            likeTime: new Date().getTime(),
            likeItemId: this.detailData.id,
          };
          AddLike(query).then(res => {
            this.detailData.is_digg.push(res.data);
          });
          EditForumPost(id, this.detailData).then((res) => {
            this.isClickLike = true;
          });
        }
      }
    },
    clickCommentLike(i) {
      if (this.isLogin == false) {
        this.showLoginPane = true;
        return;
      }
      if (i.is_digg.length != 0) {
        // 取消点赞
        let likeCount = i.Comment_goodcount - 1;
        DeleteLike(i.is_digg[0].Id).then(res => {
          i.is_digg = [];
          let query = {
            id: i.F_Id,
            commentGoodcount: likeCount,
          };
          EditComment(i.F_Id, query).then(res => {
            i.Comment_goodcount = likeCount;
          });
        });
      } else {
        // 点赞
        let likeCount = i.Comment_goodcount + 1;
        let query = {
          likeAdmin: this.userInfo.username,
          likeAdminId: this.userInfo.id,
          likeTime: new Date().getTime(),
          likeItemId: i.F_Id,
        };
        AddLike(query).then(res => {
          i.is_digg.push(res.data);
          let query = {
            id: i.F_Id,
            commentGoodcount: likeCount,
          };
          EditComment(i.F_Id, query).then(res => {
            i.Comment_goodcount = likeCount;
          });
        });

      }
    },
    clickReplyLike(i) {
      if (this.isLogin == false) {
        this.showLoginPane = true;
        return;
      }
      if (i.is_digg.length != 0) {
        // 取消点赞
        let likeCount = i.ReplyGoodcount - 1;
        DeleteLike(i.is_digg[0].Id).then(res => {
          i.is_digg = [];
          let query = {
            id: i.Id,
            ReplyGoodcount: likeCount,
          };
          EditReply(i.Id, query).then(res => {
            i.ReplyGoodcount = likeCount;
          });
        });
      } else {
        // 点赞
        let likeCount = i.ReplyGoodcount + 1;
        let query = {
          likeAdmin: this.userInfo.username,
          likeAdminId: this.userInfo.id,
          likeTime: new Date().getTime(),
          likeItemId: i.Id,
        };
        AddLike(query).then(res => {
          i.is_digg.push(res.data);
          let query = {
            id: i.Id,
            replyGoodcount: likeCount,
          };
          EditReply(i.Id, query).then(res => {
            i.ReplyGoodcount = likeCount;
          });
        });
      }
    },
    delComment(id) {
      DelComment(id).then(res => {
        this.getAllData();
      });
    },
    delReply(id) {
      DelReply(id).then(res => {
        this.getAllData();
      });
    },
    delPost() {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          DelForumPost(this.detailData.id).then(res => {
            this.$message({
              type: "success",
              message: res.msg,
              onClose: () => {
                this.$router.go(-1);
              },
            });
          });
        }).catch(() => { });
    },
    getTagName(id) {
      let tags = JSON.parse(id);
      if (tags.length > 0) {
        let result = [];
        for (var i = 0; i < this.tagList.length; i++) {
          for (var j = 0; j < tags.length; j++) {
            if (tags[j] == this.tagList[i].F_Id) {
              result.push(this.tagList[i]);
            }
          }
        }
        return result;
      }
    },
    clickPostCollect() {
      if (this.isLogin == false) {
        this.showLoginPane = true;
      } else {
        let id = this.$route.query.id;
        if (this.isCollected) {
          // 取消收藏
          this.detailData.postCollectNum = this.detailData.postCollectNum - 1;
          EditForumPost(id, this.detailData).then((res) => {
            this.isCollected = false;
          });
          DeleteCollect(this.collectedId).then(res => {
            this.$message({
              type: "success",
              message: '取消收藏',
              onClose: () => {
                this.isCollected = false;
                this.collectedId = "";
              },
            });
          });
        } else {
          // 添加收藏
          this.detailData.postCollectNum = this.detailData.postCollectNum + 1;
          EditForumPost(id, this.detailData).then((res) => {
            this.isCollected = true;
          });
          let query = {
            collectTime: new Date().getTime(),
            collectAdmin: this.userInfo.username,
            collectAdminId: this.userInfo.id,
            collectPostId: this.detailData.id,
            collectPostTitle: this.detailData.postTitle,
            collectType: '问答'
          };
          AddCollect(query).then((res) => {
            this.getCollectStatus();
            this.$message({
              type: "success",
              message: '添加收藏成功',
              onClose: () => {
                this.isCollected = true;
              },
            });
          });
        }
      }
    },
    getAllData() {
      // this.getCollectStatus();
      // let queryTag = {
      //   Tag_moduleId: this.detailData.postSmBoid,
      // };
      // GetForumModuleTagList(queryTag).then((res) => {
      //   this.tagList = [...res.data.list];
      // });
      // let postTagList = JSON.parse(this.detailData.postTagsId);
      // if (postTagList.length > 0) {
      //   let query = {
      //     Post_tagsId: postTagList[0]
      //   };
      //   GetFilterTagList(query).then(res => {
      //     this.tagPostList = res.data;
      //   });
      // }
      let query = {
        postSmboid: this.detailData.postSmboid,
        pageSize: 11,
        dataType: 0
      };
      GetForumPostsList(query).then(res => {
        // 去掉该帖本身
        this.tagPostList = res.data.list.filter(e => e.id != this.detailData.id);
      });

      this.getCommentList();
    },
    clickToComment() {
      // 点击跳转到下方评论
      let root = document.body;
      let height = 0;
      let ele = document.getElementById('commentListTop');
      do {
        height += ele.offsetTop;
        ele = ele.offsetParent;
      } while (ele !== root);

      document.documentElement.scrollTo({
        top: height,
        behavior: 'smooth'
      });
    },
    getCollectStatus() {
      if (this.isLogin) {
        let query = {
          Collect_postId: this.$route.query.id,
          Collect_adminId: this.userInfo.id,
        };
        GetCollectStatus(query).then((res) => {
          if (res.data.list.length == 0) {
            this.isCollected = false;
          } else {
            this.isCollected = true;
            this.collectedId = res.data.list[0].F_Id;
          }
        });
      }
    },
    toDetail(id) {
      const { href } = this.$router.resolve({
        name: "问答回复",
        query: {
          id,
        },
      });
      window.open(href, "_blank");
    },
    clickFocus(item) {
      if (this.isLogin == false) {
        this.showLoginPane = true;
      } else {
        if (item.isFocus && item.isFocus.length > 0) {
          // 取关操作
          DeleteFocus(item.isFocus[0].Id).then((res) => {
            this.$message({
              type: "success",
              message: res.msg,
              onClose: () => {
                this.getAllData();
              },
            });
          });
        } else {
          // 关注
          let query = {
            focusAddTime: new Date().getTime(),
            focusFriend: this.detailData.postAdmin,
            focusUserId: this.userInfo.id,
            focusUserName: this.userInfo.username,
            focusId: this.detailData.postAdminId,
          };
          AddFocus(query).then((res) => {
            this.getAllData();
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1140px;
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  // padding-top: 15px;
  background-color: rgba(230, 238, 242, 1);

  .header {
    margin: 20px;
  }

  .card {
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1) !important;
    box-sizing: border-box;
    border: unset !important;
    border-radius: 2px;
    background-color: #fefefe;
    margin-top: 10px;
    padding: 10px;
    word-wrap: break-word;
    border-radius: 4px;
    min-height: 36px;
    box-sizing: border-box;
  }

  .rightCard {
    margin-left: 30px;
  }
}

.notice-title {
  font-size: 16px;
  padding: 8px;
}

.text-center {
  text-align: center;
  overflow: hidden;
  line-height: 1.5;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;

    .col {
      padding: 15px;
      text-align: center !important;

      .col-item:first-child {
        font-size: 15px;
        color: #0099ee;
      }

      .col-item:nth-child(2) {
        font-size: 13px;
        color: #888888;
      }
    }
  }
}

.ques_tag {
  font-size: 12px;
  background-color: #ebf8ff;
  opacity: 70%;
  color: white;
  padding: 3px 10px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 5px;
}

.rank-list {
  padding: 10px;

  .rank-item {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.5;

    .rank_icon {
      height: 26px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .float-right {
    float: right;
    color: orange;
  }

  .rank-item:first-child {
    .rank-number {
      color: #fff;
      background: #0099ee;
    }
  }

  .rank-item:nth-child(2) {
    .rank-number {
      color: #fff;
      background: #0099ee;
    }
  }

  .rank-item:nth-child(3) {
    .rank-number {
      color: #fff;
      background: #0099ee;
    }
  }

  .rank-number {
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: #999;
    border-radius: 2px;
    display: inline-block;
    margin-right: 10px;
  }
}

.question_title {
  font-size: 20px;
}

.content {
  padding: 15px !important;
}

.message {
  color: #202020;
  word-break: break-all;
  line-height: 1.7;
  font-size: 15px;
  margin-bottom: 10px;

  ::v-deep img {
    max-width: 100%;
  }

  ::v-deep video {
    max-width: 100%;
  }
}

.question_name {
  padding: 10px 0;
  font-size: 14px;
  color: #868e96 !important;
}

.answer_title {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}

.replayButton {
  display: inline-block;
  // font-size: 14px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  background: none rgba(133, 144, 166, 0.1);
  border: 1px solid transparent;
  border-radius: 3px;
  margin: 10px;
  padding: 0px 6px 0px 12px;
  height: 32px;
  color: rgb(133, 144, 166);
  font-weight: 500;
}

.replyTitle {
  font-weight: bold;
  font-size: 14px;
}

.reply {
  // padding: 0 10px;
  background-color: rgba(247, 248, 250, .7);
  display: flex;

  .commentLogo {
    width: 62px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px 10px 5px 0;
    text-align: center;

    img {
      width: 100%;
      display: inline-block !important;
    }

    .logTitle {
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 36px;
      background-color: #453562;
      color: #eee;
    }
  }

  .commentContent {
    width: 100%;

    .name {
      color: #6a7780 !important;
      font-size: 12px;
      font-weight: 700;
      margin-right: 5px;
    }

    .time {
      font-size: 12px;
      color: rgba(134, 142, 150, 0.8) !important;
    }

    .emotion {
      color: #868e96 !important;
      background: url("../../../../assets/image/face.gif") no-repeat 2px 2px;
      padding-left: 20px;
      cursor: pointer;
      font-size: 14px;
    }

    .replayButton {
      display: inline-block;
      // font-size: 14px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      background: none rgba(133, 144, 166, 0.1);
      border: 1px solid transparent;
      border-radius: 3px;
      margin: 10px 0px 10px 54px;
      padding: 0px 6px 0px 12px;
      height: 32px;
      color: rgb(133, 144, 166);
      font-weight: 500;
    }

    .replayButtonSingle {
      display: flex;
      justify-content: flex-end;

      margin-bottom: 10px;

      color: rgba(134, 142, 150, 0.8);

      span {
        margin-right: 10px;
      }
    }
  }
}

.borderBottom {
  border-bottom: 20px solid rgba(133, 144, 166, 0.1);
}

.comment {
  margin-top: 10px;
  display: flex;

  .commentLogo {
    width: 62px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px 10px 5px 0;
    text-align: center;

    img {
      width: 100%;
      display: inline-block !important;
    }

    .logTitle {
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 36px;
      background-color: #453562;
      color: #eee;
    }
  }

  .commentContent {
    width: 100%;

    .name {
      color: #6a7780 !important;
      font-size: 12px;
      font-weight: 700;
      margin-right: 5px;
    }

    .time {
      font-size: 12px;
      color: rgba(134, 142, 150, 0.8) !important;
    }

    .emotion {
      color: #868e96 !important;
      background: url("../../../../assets/image/face.gif") no-repeat 2px 2px;
      padding-left: 20px;
      cursor: pointer;
      font-size: 14px;
    }

    .replayButton {
      display: inline-block;
      // font-size: 14px;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      background: none rgba(133, 144, 166, 0.1);
      border: 1px solid transparent;
      border-radius: 3px;
      margin: 10px 0px 10px 54px;
      padding: 0px 6px 0px 12px;
      height: 32px;
      color: rgb(133, 144, 166);
      font-weight: 500;
    }
  }
}

.dialogCount {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: relative;
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  display: flex;
}

.dialogCount:before {
  display: block;
  content: " ";
  position: absolute;
  inset: -50%;
  pointer-events: none;
  transform: scale(0.5, 0.5);
  border-bottom: 1px solid rgb(235, 235, 235);
}

.questionTitle {
  background-color: white;
  // height: 200px;
  padding-top: 60px;
  width: 1140px;
  margin: 0 auto;

  .quesiontTips {
    height: 30px;
    display: flex;
    margin-bottom: 10px;

    .tag {
      background: rgba(230, 240, 253, 1);
      color: rgba(51, 105, 255, 1);
      border-radius: 15px;
      font-size: 15px;
      line-height: 30px;
      margin-right: 10px;
      padding: 0 10px;
    }

  }

  .question_title {
    font-weight: 700;
    font-size: 26px;
  }
}

.QuestionHeader-content {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: flex-start;

  .QuestionHeader-main {
    box-sizing: border-box;
    flex-shrink: 0;
    padding-left: 20px;
    width: 800px;
  }

  .QuestionHeader-side {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 285px;
    min-width: 216px;
    text-align: right;

    .NumberBoard {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .QuestionFollowStatus-counts {
      margin-left: auto;
      width: 200px;
    }

    .NumberBoard-item .collectNum {
      border: 0;
      font-size: unset;
      line-height: unset;

    }

    .NumberBoard-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1;
      flex: 1 1;
      cursor: pointer;
      text-align: center;

      .NumberBoard-itemInner {
        line-height: 1.6;

      }

      .NumberBoard-itemName {
        color: #8590a6;
        font-size: 14px;
      }

      .NumberBoard-itemValue {
        font-size: 16px;
      }
    }
  }
}

.NumberBoard--divider .NumberBoard-item+.NumberBoard-item .NumberBoard-itemInner {
  border-left: 1px solid #ebebeb;
}

.questionOp {
  margin: 10px 0;
  display: flex;

  .opBtn {
    display: flex;
    font-size: 15px;
    align-items: center;
    color: #8590a6;
    cursor: pointer;
    margin-right: 15px;

    .opIcon {
      margin-right: 8px;
    }
  }
}

.commentItem:hover {
  .delButton {
    opacity: 1;
  }
}

.commentContentSelf:hover {
  .delButton {
    opacity: 1;
  }
}

.delButton {
  opacity: 0;
}

.foldBtn {
  font-size: 15px;
  color: #8590a6;
}

.containerComment {
  width: 1140px;
  margin-right: auto;
  margin-left: auto;

  .commentList {
    padding: 10px 20px;
    box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1) !important;
    box-sizing: border-box;
    background-color: #fff;

    .commentCount {
      font-size: 16px;
      font-weight: 700;
      padding: 10px 0;
    }

    .commentTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }

      .focusBtn {}

    }

    .commentContext {
      font-size: 15px;
      margin-top: 15px;
    }

    .commentTime {
      font-size: 14px;
      color: #8590a6;
      margin-top: 10px;
    }
  }

  .rightPart {
    margin-left: 10px;
    background-color: #fff;
  }
}

.hotpot-title {
  color: rgba(0, 0, 0, 1);
  border-bottom: 1px solid #eeeeee;
  padding: 10px;
  font-size: 16px;
}

.hotpot-content {
  padding: 0 15px 10px;
  line-height: 1.5;

  ul {
    margin-top: 10px;

    li {
      margin-bottom: 10px;
      color: #7d7d7d;
      font-size: 13px;

      span {
        color: #bbb;
        font-size: 12px;
      }

      .el-icon-view {
        color: #bbbbbb;
      }
    }

    li:hover {
      cursor: pointer;
    }
  }
}
</style>
<style>
.dialogClass .el-dialog__body {
  padding: 0 0 !important;
}
</style>
