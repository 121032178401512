import { render, staticRenderFns } from "./wangeditorQandA.vue?vue&type=template&id=63e3cabf"
import script from "./wangeditorQandA.vue?vue&type=script&lang=js"
export * from "./wangeditorQandA.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./wangeditorQandA.vue?vue&type=style&index=1&id=63e3cabf&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports